<template>
  <v-container
    v-if="veevaId"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div class="title-text">
          Veeva Information
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <span class="point-text mr-2">Veeva ID:</span>
        <span class="point-text point-text--regular">{{ veevaId }}</span>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <span class="point-text mr-2">Veeva Last Sync Date:</span>
        <span class="point-text point-text--regular">{{ veevaLastSyncDateFormatted }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "VeevaContactDataSection",
  mixins: [datesMixin],
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    veevaId() {
      if (Array.isArray(this.contact?.pluginData ?? [])) {
        return null;
      }

      return this.contact?.pluginData?.veeva_plugin?.veevaId ?? null;
    },
    veevaLastSyncDateFormatted() {
      if (!this.contact?.pluginData.veeva_plugin?.lastSyncDate) {
        return 'N/A';
      }

      return this.getDateWithTime(this.contact?.pluginData.veeva_plugin?.lastSyncDate);
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
